* {
  margin: 0;
  padding: 0;
}
body {
  font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
  background-color: #000;
  padding: 0;
  margin: 0;
  min-height:100vh;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: row;
}
html,body{
}
